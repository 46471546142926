import React from 'react'
import {graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text,SimpleGrid} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Aktivitaet = ({data}) => {

return(
    <div>
        <MySEO
        title="kulturaktivitäten"
        description="Einblicke in das Corporate Design und Aktivitäten der Galerie Markt 1 in Bacharach"
      />
    <Heading># kulturaktivitäten</Heading>

    <Text variant="bildtext"><b>Galerie Markt 1 – Kultur auf dem Land. </b>
     Seit Frühjahr 2020 finden, trotz Corona, Ausstellungen in dem neu bespielten Eckladen und <b>Galerieraum Markt 1</b> in Bacharach statt.
     Unter der Trägerschaft des Verschönerungsvereins Bacharach e.V. kuratiert Katrin Gloggengießer das wechselnde Programm und betreut gemeinsam mit René Broich den digitalen Auftritt.
<br/> <br/>
    D O W N L O A D &nbsp; F L Y E R:</Text>

<Box><SimpleGrid columns={[1, 2, 3]} spacing={0}>
    < a href="/pdf/Flyer_Bacchanale_email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Bacchanale</Text></a>
    < a href="/pdf/Flyer_UrbanSketchers_email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Urban Sketchers / Victor Hugo</Text></a>
    < a href="/pdf/Flyer_Huettchen_email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Hüttchen/BUGA</Text></a>
    < a href="/pdf/Flyer_Camera-Obscura-email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Camera Obscura</Text></a>
    < a href="/pdf/Flyer_Bacchanale_email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Bacchanale</Text></a>
    < a href="/pdf/Flyer_Weltenfrauen_email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Weltenfrauen / Ellen Schmauss</Text></a>
    < a href="/pdf/Flyer_Sattler_email.pdf" target="_blank" rel="noopener noreferrer"><Text variant="bildtext"> &rarr; Flyer Evelyn / Evelyn Sattler</Text></a>
</SimpleGrid>
</Box>

  <Flex>
   <Box width={["100%","100%","49%"]}>
        <GatsbyImage alt="Markt1 Impression" image= {data.bilder.edges[0].node.childImageSharp.gatsbyImageData} />
   </Box>
   <Box width={["100%","100%","49%"]}>
        <GatsbyImage alt="Markt1 Impression" image= {data.bilder.edges[1].node.childImageSharp.gatsbyImageData} />
   </Box>
     </Flex>
    </div>
)}


export const AktiQuery = graphql`
 query {
  bilder:allFile(filter: {absolutePath: {regex: "/_markt1/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
 }


`

  export default Aktivitaet
